import axios from "../../instance";
import * as url from "../../constants/urlConstants";

export const KEY_METRIC_LIST = "KEY_METRIC_LIST";
export const KEY_METRIC_TOTAL_RECORDS = "KEY_METRIC_TOTAL_RECORDS";
export const KEY_METRIC = "KEY_METRIC";

export const addingKeyMetrics = (data) => {
    return async(dispatch) => {
        const res = await axios.post(url.addingKeyMetrics, data);
        return res;
    }
}

export const getKeyMetricsList = (data) => {
    return {
      type: KEY_METRIC_LIST,
      data: data,
    };
  };
  
  export const getKeyMetricsRecord = (data) => {
    return {
      type: KEY_METRIC_TOTAL_RECORDS,
      data: data,
    };
  };
  
  export const gettingKeyMetricsList = (page='', search='') => {
    return async (dispatch) => {
      const res = await axios.get(url.gettingKeyMetricsList(page, search));
      if (res.error === true) {
        return res;
      } else if (res && res.status && res.status === 500) {
        return 500;
      } else if (
        res.data &&
        res.data.response &&
        res.data.response.length === 0
      ) {
        //   dispatch(getTaskPointList(res.data.response));
        dispatch(getKeyMetricsRecord(res.data.total));
        return 404;
      } else {
        dispatch(getKeyMetricsList(res.data.response));
        dispatch(getKeyMetricsRecord(res.data.total));
        return 200;
      }
    };
  };

  export const getKeyMetric = (data) => {
    return {
      type: KEY_METRIC,
      data: data,
    };
  };

  
  
  export const gettingKeyMetric = (id) => {
    return async (dispatch) => {
      const res = await axios.get(url.gettingKeyMetric(id));
      if (res.data && res.data.error === false) {
        if (res.data.data) {
          dispatch(getKeyMetric(res.data.data));
        } else {
          return { status: 404 };
        }
      }
      return res;
    };
  };

  export const updatingKeyMetrics = (id, data) => {
    return async (dispatch) => {
      const res = await axios.post(url.updatingKeyMetrics(id), data);
      return res;
    };
  };

  export const changingKeyMetricStatus = (id, status) => {
    return async (dispatch) => {
      // const data = {
      //   // user_id: id,
      //   status: status === 0 ? 1 : 0,
      // };
      const res = await axios.post(url.changingKeyMetricStatus(id), status);
      if (res && res.status === 200) {
        return 200;
      } else if (res && res.status === 500) {
        return 500;
      }
    };
  };

  export const deletingKeyMetric = (id) => {
    return async (dispatch) => {
      const res = await axios.get(url.deletingKeyMetric(id));
      return res;
    };
  };