import * as actionTypes from "../actions/taskPointAction";

const initialState = {
  taskList: [],
  totalTaskRecords: "",
  task: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TASK_POINT_LIST: {
      return {
        ...state,
        taskList: action.data,
      };
    }
    case actionTypes.TASK_TOTAL_RECORDS: {
      return {
        ...state,
        totalTaskRecords: action.data,
      };
    }
    case actionTypes.TASK_POINT: {
      return {
        ...state,
        task: action.data,
      };
    }
    default:
      return { ...state };
  }
};

export default reducer;
