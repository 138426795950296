import axios from "../../instance";
import * as url from "../../constants/urlConstants";

export const ORDER_HISTORY = "ORDER_HISTORY";
export const TOTAL_HISTORY_ORDER = "TOTAL_HISTORY_ORDER";

export const getOrderhistory = (data) => {
  return {
    type: ORDER_HISTORY,
    data: data,
  };
};

export const getHistoryTotal = (data) => {
  return {
    type: TOTAL_HISTORY_ORDER,
    data: data,
  };
};

export const gettingOrderHistory = (page, search) => {
  return async (dispatch) => {
    const res = await axios.get(url.gettingOrderHistory(page, search));
    if (res.error === true) {
      return { status: 400, message: res.message };
    } else if (res && res.status && res.status === 500) {
      return res;
    } else if (
      res.data &&
      res.data.response &&
      res.data.response.length === 0
    ) {
      //   dispatch(getTaskPointList(res.data.response));
      dispatch(getHistoryTotal(res.data.total));
      return { status: 404 };
    } else {
      dispatch(getOrderhistory(res.data.response));
      dispatch(getHistoryTotal(res.data.total));
      return { status: 200 };
    }
  };
};
