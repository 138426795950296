import axios from '../../instance';
import * as url from '../../constants/urlConstants';

export const LEVEL_POINT = "LEVEL_POINT";
export const DAILY_COMPETITION_RECORD = "DAILY_COMPETITION_RECORD";
export const TOTAL_DAILY_COMPETITION = "TOTAL_DAILY_COMPETITION";
export const SEND_EMAIL_INITIAL = "SEND_EMAIL_INITIAL"


export const intialEmail = (data)=>{
    return{
        type:"initialEmail",
        data:data  
    }
 
}

export const sendingEmail = (data)=>{
    return{
        type:"sendingEmail",
        data:data
    }
}

export const emailFail = (data) =>{
  return{
    type:"emailSent",
    data:data
  }
}
export const getLevelPoint = (data) => {
    return {
        type: LEVEL_POINT,
        data: data
    }
}
export const sendEmailInitial = (data)=>{
    return{
        type:SEND_EMAIL_INITIAL,
        data:data
    }

}


export const gettingLevelPoint = () => {
    return async(dispatch) => {
        const res = await axios.get(url.gettingLevelPoint);
        if(res && res.status && res.status === 200)
        {
            dispatch(getLevelPoint(res.data?.data?.level_system))
        }
        return res
    }
}

export function updateLevelPoints(value){
    return async (dispatch) => {
        const data = {
            level_points:  value
        }
        const res = await axios.post(url.updateLevelPoints, data)
        return res
    }
}

export const getDailyCompetition = (data) => {
    return {
        type: DAILY_COMPETITION_RECORD,
        data: data
    }
}

export const getTotalRecords = (data) => {
    return {
        type: TOTAL_DAILY_COMPETITION,
        data: data
    }
}

export const gettingDailyCompetitionRecord = (limit, search, page, task, date = "daily") => {
    return async(dispatch) => {
        const res = await axios.get(url.gettingDailyCompetitionRecord(limit,search,page,task,date));
        if (res.status === 200) {
            if (res.data.response && res.data.response.length > 0) {
              dispatch(getDailyCompetition(res.data.response));
              dispatch(getTotalRecords(res.data.total))
              return {status: 200};
            } else {
            dispatch(getTotalRecords(res.data.total));
              return {status:404};
            }
          } else if (res && res.status === 500) {
            return {status: 500};
          }
    }
}