import * as actionTypes from "../actions/commonAction";

const initialState = {
    level_points: '',
    daily_competition_record: '',
    total_daily_competition_record: '',
    emailState:false,
    emailMessage:""
}

const reducer = (state=initialState, action)=>{
    switch(action.type)
    {
        case actionTypes.LEVEL_POINT: {
            return {
                ...state,
                level_points: action.data
            }
        }
        case actionTypes.DAILY_COMPETITION_RECORD: {
            return {
                ...state,
                daily_competition_record: action.data
            }
        }
        case actionTypes.TOTAL_DAILY_COMPETITION: {
            return {
                ...state,
                total_daily_competition_record: action.data
            }

            
        }


        case  "initialEmail" : {
            return {
                ...state,
                emailState:true
            }
        }

        case  "sendingEmail" : {
            return {
                ...state,
                emailState:action.data.state,
                message:action.data.message
            }
        }

        case  "emailFail" : {
            return {
                ...state,
                emailState:action.data.state,
                message:action.data.message
            }
        }
        
        default : return state
    }
}

export default reducer