import * as constants from "../../constants/appConstants";
import * as url from "../../constants/urlConstants";
import axios from "../../instance";

export const loggingUser = (data) => {
  return async (dispatch) => {
    const res = await axios.post(url.loggingUser, data);
    if (res && res.data && res.data.error === false) {
      localStorage.setItem("quota-games-token", res.data.data.jwt_token);
      localStorage.setItem("quota-games-id", res.data.data.id);
    }
    return res;
  };
};

export const loggingOut = () => {
  return async (dispatch) => {
    await localStorage.removeItem("quota-games-token");
    await localStorage.removeItem("quota-games-id");
    return true;
  };
};
