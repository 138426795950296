import * as actionTypes from "../actions/competitionAction";

const initialState = {
    competitionList: '',
    total_competition: '',
    particularCompetition: '',
    usersWithRespectToParticularCompetition: '',
    total_competition_users: '',
    particularCompetitionTasks: '',
    singleCompetition: '',
    singleCompetitionTask: '',
    singleCompetitionUser: '',
    runList :false
}

const reducer = (state = initialState, action) => {
    switch(action.type)
    {
        case actionTypes.GET_COMPETITIONS: {
            return {
                ...state,
                competitionList: action.data
            }
        }
        case actionTypes.TOTAL_COMPETITION_RECORD: {
            return {
                ...state,
                total_competition: action.data
            }
        }
        case actionTypes.GET_PARTICULAR_COMPETITION: {
            return {
                ...state,
                particularCompetition: action.data
            }
        }
        case actionTypes.GET_PARTICULAR_COMPETITION_USERS_LIST: {
            return {
                ...state,
                usersWithRespectToParticularCompetition: action.data
            }
        }
        case actionTypes.TOTAL_COMPETITION_USERS: {
            return {
                ...state,
                total_competition_users: action.data
            }
        }
        case actionTypes.GET_PARTICULAR_COMPETITION_TASKS: {
            return {
                ...state,
                particularCompetitionTasks: action.data
            }
        }
        case actionTypes.GET_SINGLE_COMPETITION: {
            return {
                ...state,
                singleCompetition: action.data
            }
        }
        case actionTypes.GET_SINGLE_COMPETITION_TASK_DATA: {
            return {
                ...state,
                singleCompetitionTask: action.data
            }
        }
        case actionTypes.GET_SINGLE_COMPETITION_USER_DATA: {
            return {
                ...state,
                singleCompetitionUser: action.data
            }
        }
        case "run_list" :{
            console.log(state.runList)
            return {
                ...state, 
                runList : !state.runList
            }
        }
        default : {
            return state
        }
    }
}

export default reducer;