import axios from "../../instance";
// import * as constants from "../../constants/appConstants";
import * as url from "../../constants/urlConstants";

export const ADD_USER = "ADD_USER";
export const GET_ALL_USER = "GET_ALL_USER";
export const GET_USER_POINTS = "GET_USER_POINTS";
export const GET_USER_CAMPAIGNS = "GET_USER_CAMPAIGNS";
export const UPDATE_USER_CAMPAIGNS = "UPDATE_USER_CAMPAIGNS";
export const GET_USER = "GET_USER";
export const DELETE_USER = "DELETE_USER";
export const GET_RECENT_USERS = "GET_RECENT_USERS";
export const GET_TASK_HISTORY = "GET_TASK_HISTORY";
export const GET_USERS_WITHOUT_COMPETITION = "GET_USERS_WITHOUT_COMPETITION";
export const GET_USER_KEY_METRIC_HISTORY = "GET_USER_KEY_METRIC_HISTORY";
export const USER_KEY_METRIC_COMPETITION = "USER_KEY_METRIC_COMPETITION";
export const GET_PARTICULAR_USER_AWARD_HISTORY =
  "GET_PARTICULAR_USER_AWARD_HISTORY";
export const GET_TOTAL_PARTICULAR_USER_AWARD_HISTORY =
  "GET_TOTAL_PARTICULAR_USER_AWARD_HISTORY";
export const GET_USER_AWARD_HISTORY_FOR_UPDATE =
  "GET_USER_AWARD_HISTORY_FOR_UPDATE";

export const addingUser = (data) => {
  return async (dispatch) => {
    const res = await axios.post(url.addingUser, data);
    if (res && res.status === 200) {
      return 200;
    } else if (res && res.error && res.error === true) {
      return res;
    }
  };
};

export const getUser = (data) => {
  return {
    type: GET_USER,
    data: data,
  };
};

export const gettingUser = (data) => {
  return async (dispatch) => {
    const res = await axios.get(url.gettingUser(data));
    if (res && res.status && res.status === 200) {
      dispatch(getUser(res.data.data));
    } else if (res && res.error === true && res.message === "No user id") {
      return 404;
    } else {
      return 500;
    }
  };
};

export const getAllUser = (data) => {
  return {
    type: GET_ALL_USER,
    data: data,
  };
};

export const gettingAllUser = (page, search, type) => {
  return async (dispatch) => {
    const res = await axios.get(url.gettingAllUser(page, search, type));
    if (res.error === true) {
      return res;
    } else if (res && res.status && res.status === 500) {
      return 500;
    } else if (
      res.data &&
      res.data.response &&
      res.data.response.length === 0
    ) {
      dispatch(getAllUser(res.data));
      return 404;
    } else {
      dispatch(getAllUser(res.data));
      return 200;
    }
  };
};

export const getUserPointsData = (data) => {
  return {
    type: GET_USER_POINTS,
    data: data,
  };
};

export const gettingUserPoints = (user_id, page) => {
  return async (dispatch) => {
    const res = await axios.get(url.getUserPoints(user_id, page));
    if (res.error === true) {
      return res;
    } else if (res && res.status && res.status === 500) {
      return 500;
    } else if (
      res.data &&
      res.data.response &&
      res.data.response.length === 0
    ) {
      dispatch(getUserPointsData(res.data.data));
      return 404;
    } else {
      dispatch(getUserPointsData(res.data.data));
      return 200;
    }
  };
};

export const UpdateCampaingsWTaskData = (data) => {
  return {
    type: UPDATE_USER_CAMPAIGNS,
    data: data,
  };
};

export const updatIngCampaingsWTask = (
  user_id,
  campaign_id,
  task_id,
  type,
  value
) => {
  return async (dispatch) => {
    var data = JSON.stringify({
      user_id: user_id,
      campaign_id: campaign_id,
      task_id: task_id,
      type: type,
      value: value,
    });
    var config = {
      method: "post",
      url: url.updateCampaingsWTask,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    console.log(config);
    const res = await axios(config);
    if (res.error === true) {
      return res;
    } else if (res && res.status && res.status === 500) {
      return 500;
    } else if (
      res.data &&
      res.data.response &&
      res.data.response.length === 0
    ) {
      dispatch(UpdateCampaingsWTaskData(res.data.data));
      return 404;
    } else {
      dispatch(UpdateCampaingsWTaskData(res.data.data));
      return res.data.data;
    }
  };
};

export const getUserCampaingsWTaskData = (data) => {
  return {
    type: GET_USER_CAMPAIGNS,
    data: data,
  };
};

export const gettingCampaingsWTask = (user_id, task_id, page) => {
  return async (dispatch) => {
    const res = await axios.get(
      url.getUserCampaingsWTask(user_id, task_id, page)
    );
    if (res?.data && res.data?.error && res.data.error === true) {
      return res;
    } else if (res && res.status && res.status === 500) {
      return 500;
    } else if (res.data && res.data.length === 0) {
      dispatch(getUserCampaingsWTaskData(res.data));
      return 404;
    } else {
      dispatch(getUserCampaingsWTaskData(res.data));
      return res.data;
    }
  };
};

export const updatingUser = (id, data) => {
  return async (dispatch) => {
    const res = await axios.post(url.updatingUser(id), data);
    if (res && res.status === 200) {
      return 200;
    } else if (res && res.error === true) {
      return res;
    }
  };
};

export const deleteUser = (id) => {
  return {
    type: DELETE_USER,
    data: id,
  };
};

export const deletingUser = (id) => {
  return async (dispatch) => {
    const res = await axios.get(url.deletingUser(id));
    if (res && res.status === 200) {
      // dispatch(deleteUser(id));
      return 200;
    } else if (res && res.status && res.status === 500) {
      return 500;
    }
  };
};

export const getRecentUsers = (data) => {
  return {
    type: GET_RECENT_USERS,
    data: data,
  };
};

export const gettingRecentUsers = () => {
  return async (dispatch) => {
    const res = await axios.get(url.gettingRecentUsers);
    if (res.status === 200) {
      if (res.data.response && res.data.response.length > 0) {
        dispatch(getRecentUsers(res.data.response));
        return 200;
      } else {
        return 404;
      }
    } else if (res && res.status === 500) {
      return 500;
    }
  };
};

export const changingStatus = (id, status) => {
  return async (dispatch) => {
    const data = {
      user_status_id: id,
      status: status === 0 ? 1 : 0,
    };
    const res = await axios.post(url.changingStatus, data);
    if (res && res.data && res.data.error === false) {
      return 200;
    } else if (res && res.data && res.data.error === true) {
      return 400;
    } else if (res && res.status === 500) {
      return 500;
    }
  };
};

export const getTaskHistory = (data) => {
  return {
    type: GET_TASK_HISTORY,
    data: data,
  };
};

export const gettingTaskHistory = (id) => {
  return async (dispatch) => {
    const res = await axios.get(url.gettingTaskHistory(id));
    if (res.status === 200) {
      dispatch(getTaskHistory(res.data.data));
      dispatch(getUser(res.data.metadata));
    }
    return res;
  };
};

export const updatingTaskPoints = (data) => {
  return async (dispatch) => {
    const res = await axios.post(url.updatingUserTaskPoints, data);
    if (res.data && res.data.error === false) {
      return { status: 200 };
    } else if (res.data && res.data.error === true) {
      return { status: 400, message: res.message };
    } else return res;
  };
};

export const updatingGamePoints = (data) => {
  return async (dispatch) => {
    const res = await axios.post(url.updatingGamePoints, data);
    return res;
  };
};

export const addingUserTaskPoints = (data) => {
  return async (dispatch) => {
    const res = await axios.post(url.addingUserTaskPoints, data);
    return res;
  };
};

export const getAllUsersWithoutPagination = (data) => {
  return {
    type: GET_USERS_WITHOUT_COMPETITION,
    data: data,
  };
};

export const gettingAllUsersWithoutPagination = () => {
  return async (dispatch) => {
    const res = await axios.get(url.gettingAllUsersWithoutPagination);
    if (res.status === 200) {
      dispatch(getAllUsersWithoutPagination(res.data.data));
      // dispatch(getUser(res.data.metadata));
    }
  };
};

export const getKeyMetric = (data) => {
  return {
    type: GET_USER_KEY_METRIC_HISTORY,
    data: data,
  };
};

export const userCompetitionForKeyMetric = (data) => {
  return {
    type: USER_KEY_METRIC_COMPETITION,
    data: data,
  };
};

export const gettingUserKeyMetricsHistory = (id) => {
  return async (dispatch) => {
    const res = await axios.get(url.gettingUserKeyMetricsHistory(id));
    if (res.data?.error === false) {
      dispatch(getKeyMetric(res.data.data));
      dispatch(userCompetitionForKeyMetric(res.data.metadata));
      // dispatch(getUser(res.data.metadata));
    }
    return res;
  };
};

export const updatingKeyMetricPoints = (data) => {
  return async (dispatch) => {
    const res = await axios.post(url.updatingKeyMetricPoints, data);
    if (res.data && res.data.error === false) {
      return { status: 200 };
    } else if (res.data && res.data.error === true) {
      return { status: 400, message: res.message };
    } else return res;
  };
};

export const updatingUserCoins = (id, data) => {
  return async (dispatch) => {
    const res = await axios.post(url.updatingUserCoins(id), data);
    if (res.data && res.data.error === false) {
      return { status: 200 };
    } else if (res.data && res.data.error === true) {
      return { status: 400, message: res.message };
    } else return res;
  };
};

export const givingAwardToParticularUser = (id, data) => {
  return async (dispatch) => {
    const res = await axios.post(url.givingAwardToParticularUser(id), data);
    if (res.data && res.data.error === false) {
      return { status: 200 };
    } else if (res.data && res.data.error === true) {
      return { status: 400, message: res.message };
    } else return res;
  };
};

export const getParticularAwardHistory = (data) => {
  return {
    type: GET_PARTICULAR_USER_AWARD_HISTORY,
    data: data,
  };
};

export const getTotalParticularAwardHistory = (data) => {
  return {
    type: GET_TOTAL_PARTICULAR_USER_AWARD_HISTORY,
    data: data,
  };
};

export const gettingParticularAwardHistory = (id, page, search, filter) => {
  return async (dispatch) => {
    const res = await axios.get(
      url.gettingParticularAwardHistory(id, page, search, filter)
    );
    // console.log(res);
    if (res.data.error === true) {
      return res;
    } else if (res && res.status && res.status === 500) {
      return { status: 500 };
    } else if (
      res.data &&
      res.data.data &&
      res.data.data.user_awards.length === 0
    ) {
      dispatch(getTotalParticularAwardHistory(res.data?.total));
      dispatch(getUser(res.data?.data?.user_data));
      return { status: 404 };
    } else {
      dispatch(getUser(res.data?.data?.user_data));
      dispatch(getParticularAwardHistory(res.data?.data?.user_awards));
      dispatch(getTotalParticularAwardHistory(res.data?.total));
      return { status: 200 };
    }
  };
};

export const getUserAwardHistoryForUpdate = (data) => {
  return {
    type: GET_USER_AWARD_HISTORY_FOR_UPDATE,
    data: data,
  };
};

export const gettingUserAwardHistoryForUpdate = (userId, awardId) => {
  return async (dispatch) => {
    const res = await axios.get(
      url.gettingUserAwardHistoryForUpdate(userId, awardId)
    );
    // console.log(res);
    if (res.data?.error === false) {
      dispatch(getUserAwardHistoryForUpdate(res?.data?.data));
      return { status: 200 };
    } else if (res.data?.error === true) {
      return { status: 404, message: res.message };
    } else return res;
  };
};

export const updatingUserAward = (id, data) => {
  return async (dispatch) => {
    const res = await axios.post(url.updatingUserAward(id), data);
    if (res.data && res.data.error === false) {
      return { status: 200 };
    } else {
      return res;
    }
  };
};
