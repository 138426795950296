import axios from "../../instance";
import * as url from "../../constants/urlConstants";

export const POINT_SETTINGS = "POINT_SETTINGS";

export const updatingPoints = (data) => {
  return async (dispatch) => {
    const res = await axios.post(url.updatingPoints, data);
    if (res.data && res.data.error === false) {
      return { status: 200 };
    } else return res;
  };
};

export const getPoints = (data) => {
  return {
    type: POINT_SETTINGS,
    data: data,
  };
};

export const gettingPoints = () => {
  return async (dispatch) => {
    const res = await axios.get(url.gettingPoints);
    if (res.status === 200) {
      dispatch(getPoints(res.data && res.data.data && res.data.data[0]));
    }
    return res;
  };
};
