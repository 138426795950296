import * as actionTypes from "../actions/dashboardAction";

const initialState = {
  dashboardTaskPoint: [],
  totalUser:"",
  topRank: '',
  totalProducts: ''
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DASHBOARD_TASK_POINT: {
      return {
        ...state,
        dashboardTaskPoint: action.data,
      };
    }
    case actionTypes.DASHBOARD_CARD_DATA:{
      return {
        ...state,
        totalUser:action.data?.user,
        topRank: action.data?.top_rank,
        totalProducts: action.data?.product
      }
    }
    default:
      return { ...state };
  }
};

export default reducer;
