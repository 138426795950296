import * as actionTypes from "../actions/orderHistoryAction";

const initialState = {
  orderHistoryList: [],
  orderHistoryRecords: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ORDER_HISTORY: {
      return {
        ...state,
        orderHistoryList: action.data,
      };
    }
    case actionTypes.TOTAL_HISTORY_ORDER: {
      return {
        ...state,
        orderHistoryRecords: action.data,
      };
    }
    default:
      return { ...state };
  }
};

export default reducer;
