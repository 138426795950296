import axios from "../../instance";
import * as url from "../../constants/urlConstants";

export const DASHBOARD_TASK_POINT = "DASHBOARD_TASK_POINT";
export const DASHBOARD_CARD_DATA = "DASHBOARD_CARD_DATA";

export const getDashboardTaskPoint = (data) => {
  return {
    type: DASHBOARD_TASK_POINT,
    data: data,
  };
};

export const gettingDashboardTaskPoints = () => {
  return async (dispatch) => {
    const res = await axios.get(url.gettingDashboardTaskPoints);
    if (res.data && res.data.error === false) {
      if (res.data && res.data.response && res.data.response.length === 0) {
        return { status: 404 };
      } else if (
        res.data &&
        res.data.response &&
        res.data.response.length > 0
      ) {
        dispatch(getDashboardTaskPoint(res.data.response));
        return { status: 200 };
      }
    } else if (res.data && res.data.error === true) {
      return { status: 404, message: res.data.message };
    } else {
      return res;
    }
  };
};

export const getCardData = (data) => {
  return {
    type:DASHBOARD_CARD_DATA,
    data: data
  }
}

export const gettingCardData = (data) => {
  return async(dispatch) => {
    const res = await axios.get(url.gettingCardData);
    if (res.data && res.data.error === false) {
        dispatch(getCardData(res.data.data));
      }
      return res
    } 
}

