import * as actionTypes from "../actions/settingAction";

const initialState = {
  setting: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.POINT_SETTINGS: {
      return {
        ...state,
        setting: action.data,
      };
    }
    default:
      return { ...state };
  }
};

export default reducer;
