import * as actionTypes from "../actions/productAction";

const initialState = {
  productsList: [],
  totalPage: "",
  product: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PRODUCT_LIST: {
      return {
        ...state,
        productsList: action.data,
      };
    }
    case actionTypes.TOTAL_PRODUCT: {
      return {
        ...state,
        totalPage: action.data.total,
      };
    }
    case actionTypes.PRODUCT: {
      return {
        ...state,
        product: action.data,
      };
    }
    default:
      return { ...state };
  }
};

export default reducer;
