import * as url from "../../constants/urlConstants";
import axios from "../../instance";

export const GET_ALL_NOTIFICATIONS = "GET_ALL_NOTIFICATIONS";
export const GET_NOTIFICATION_COUNT = "GET_NOTIFICATION_COUNT";

export const getAllNotifications = (data) => {
    return {
        type: GET_ALL_NOTIFICATIONS,
        data: data
    }
}

export const getTotalNotificationCount = (data) => {
    return {
        type: GET_NOTIFICATION_COUNT,
        data: data
    }
}

export const gettingAllNotifications = (page, search, filter) => {
    return async(dispatch) => {
        const res = await axios.get(url.gettingAllNotifications(page, search, filter));
        if (res.error === true) {
            return res;
          } else if (res && res.status && res.status === 500) {
            return 500;
          } else if (
            res.data &&
            res.data.response &&
            res.data.response.length === 0
          ) {
            //   dispatch(getTaskPointList(res.data.response));
            dispatch(getTotalNotificationCount(res.data.total));
            return 404;
          } else {
            dispatch(getAllNotifications(res.data.response));
            dispatch(getTotalNotificationCount(res.data.total));
            return 200;
          }
    }
}

export const updatingRequestStatus = (id, status) => {
  return async(dispatch) => {
    const obj = {
      status: status,
      confirmation_id: id
    }
    const res = await axios.post(url.updatingRequestStatus, obj);
    return res;
  }
}