import axios from "../../instance";
import * as url from "../../constants/urlConstants";

export const TASK_POINT_LIST = "TASK_POINT_LIST";
export const TASK_TOTAL_RECORDS = "TASK_TOTAL_RECORDS";
export const TASK_POINT = "TASK_POINT";

export const getTaskPointList = (data) => {
  return {
    type: TASK_POINT_LIST,
    data: data,
  };
};

export const getAllTasks = (data) => {
  return {
    type: TASK_TOTAL_RECORDS,
    data: data,
  };
};

export const gettingTaskPointsList = (page='', search='') => {
  return async (dispatch) => {
    const res = await axios.get(url.gettingTaskPointsList(page, search));
    if (res.error === true) {
      return res;
    } else if (res && res.status && res.status === 500) {
      return 500;
    } else if (
      res.data &&
      res.data.response &&
      res.data.response.length === 0
    ) {
      //   dispatch(getTaskPointList(res.data.response));
      dispatch(getAllTasks(res.data.total));
      return 404;
    } else {
      dispatch(getTaskPointList(res.data.response));
      dispatch(getAllTasks(res.data.total));
      return 200;
    }
  };
};

export const addingTaskPoint = (data) => {
  return async (dispatch) => {
    const res = await axios.post(url.addingTaskPoint, data);
    return res;
  };
};

export const getTask = (data) => {
  return {
    type: TASK_POINT,
    data: data,
  };
};

export const gettingTask = (id) => {
  return async (dispatch) => {
    const res = await axios.get(url.gettingTask(id));
    if (res.data && res.data.error === false) {
      if (res.data.data) {
        dispatch(getTask(res.data.data));
      } else {
        return { status: 404 };
      }
    }
    return res;
  };
};

export const updatingTaskPoints = (id, data) => {
  return async (dispatch) => {
    const res = await axios.post(url.updatingTaskPoints(id), data);
    return res;
  };
};

export const deletingTaskPoints = (id) => {
  return async (dispatch) => {
    const res = await axios.get(url.deletingTaskPoints(id));
    return res;
  };
};

export const changingTaskPointStatus = (id, status) => {
  return async (dispatch) => {
    // const data = {
    //   // user_id: id,
    //   status: status === 0 ? 1 : 0,
    // };
    const res = await axios.post(url.changingTaskPointStatus(id), status);
    if (res && res.status === 200) {
      return 200;
    } else if (res && res.status === 500) {
      return 500;
    }
  };
};
