import * as actionTypes from "../actions/keymetricsAction";

const initialState = {
    totalRecords:'',
    keyMetricsList: '',
    keyMetric:''
}

const reducer = (state=initialState, action) => {
    switch(action.type){
        case actionTypes.KEY_METRIC_TOTAL_RECORDS: {
            return{
                ...state,
                totalRecords: action.data
            }
        }
        case actionTypes.KEY_METRIC_LIST: {
            return{
                ...state,
                keyMetricsList: action.data
            }
        }
        case actionTypes.KEY_METRIC: {
            return {
                ...state,
                keyMetric: action.data
            }
        }
        default : return state
    }
}

export default reducer;