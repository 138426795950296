import axios from "../../instance";
import * as url from "../../constants/urlConstants";

export const PRODUCT_LIST = "PRODUCT_LIST";
export const TOTAL_PRODUCT = "TOTAL_PRODUCT";
export const PRODUCT = "PRODUCT";

export const getProductList = (data) => {
  return {
    type: PRODUCT_LIST,
    data: data,
  };
};

export const totalProduct = (data) => {
  return {
    type: TOTAL_PRODUCT,
    data: data,
  };
};

export const gettingProductList = (page, search) => {
  return async (dispatch) => {
    const res = await axios.get(url.gettingProductList(page, search));
    if (res.data && res.data.error === false) {
      if (res.data && res.data.response && res.data.response.length === 0) {
        return { status: 404 };
      } else if (
        res.data &&
        res.data.response &&
        res.data.response.length > 0
      ) {
        dispatch(getProductList(res.data.response));
        dispatch(totalProduct(res.data));
        return { status: 200 };
      }
    } else if (res.data && res.data.error === true) {
      return { status: 400 };
    } else {
      return res;
    }
  };
};

export const getProduct = (data) => {
  return {
    type: PRODUCT,
    data: data,
  };
};

export const gettingProduct = (id) => {
  return async (dispatch) => {
    const res = await axios.get(url.gettingProduct(id));
    if (res.data && res.data.error === false) {
      if (res.data.data) {
        dispatch(getProduct(res.data.data));
      } else {
        return { status: 404 };
      }
    }
    return res;
  };
};

export const addingProduct = (data) => {
  return async (dispatch) => {
    const res = await axios.post(url.addingProduct, data);
    if (res.data && res.data.error === false) {
      return { status: 200 };
    } else if (res.data && res.data.error === true) {
      return { status: 404, message: res.data.message };
    } else {
      return res;
    }
  };
};

export const updatingProduct = (id, data) => {
  return async (dispatch) => {
    const res = await axios.post(url.updatingProduct(id), data);
    if (res.data && res.data.error === true) {
      return { status: 400, message: res.data.message };
    } else if (res.data && res.data.error === false) {
      return { status: 200 };
    } else if (res.status && res.status === 500) {
      return res;
    }
  };
};

export const deletingProduct = (id) => {
  return async (dispatch) => {
    const res = await axios.get(url.deletingProduct(id));
    return res;
  };
};

export const changingProductStatus = ( data) => {
  return async (dispatch) => {
    // const data = {
    //   // user_id: id,
    //   product_id: id,
    //   status: status === 0 ? 1 : 0,
    // };
    const res = await axios.post(url.changingProductStatus, data);
    if (res && res.status === 200) {
      return 200;
    } else if (res && res.status === 500) {
      return 500;
    }
  };
};
