import * as actionTypes from "../actions/userAction";

const initialState = {
  users: [],
  user: "",
  pages: "",
  userPoints: [],
  recent_users: [],
  taskHistory: "",
  allUsers: "",
  keyMetricHistory: "",
  userKeyMetricCompetition: "",
  particularUserAwardHistory: [],
  totalParticularUserAwards: "",
  userAwardHistoryForUpdate: "",
  userCampaginsData: [],
  updatedCampaign: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_USER: {
      return {
        ...state,
        users: action.data.response,
        pages: action.data.total,
      };
    }
    case actionTypes.GET_USER_POINTS: {
      return {
        ...state,
        userPoints: action.data,
      };
    }
    case actionTypes.GET_USER_CAMPAIGNS: {
      return {
        ...state,
        userCampaginsData: action.data,
      };
    }
    case actionTypes.UPDATE_USER_CAMPAIGNS: {
      return {
        ...state,
        updatedCampaign: action.data,
      };
    }
    case actionTypes.GET_USER: {
      return {
        ...state,
        user: action.data,
      };
    }
    case actionTypes.DELETE_USER: {
      const updatedArray = state.users.filter((el) => el.id !== action.data);
      return {
        ...state,
        users: updatedArray,
      };
    }
    case actionTypes.GET_RECENT_USERS: {
      return {
        ...state,
        recent_users: action.data,
      };
    }
    case actionTypes.GET_TASK_HISTORY: {
      return {
        ...state,
        taskHistory: action.data,
      };
    }
    case actionTypes.GET_USERS_WITHOUT_COMPETITION: {
      return {
        ...state,
        allUsers: action.data,
      };
    }
    case actionTypes.GET_USER_KEY_METRIC_HISTORY: {
      return {
        ...state,
        keyMetricHistory: action.data,
      };
    }
    case actionTypes.USER_KEY_METRIC_COMPETITION: {
      return {
        ...state,
        userKeyMetricCompetition: action.data,
      };
    }
    case actionTypes.GET_PARTICULAR_USER_AWARD_HISTORY: {
      return {
        ...state,
        particularUserAwardHistory: action.data,
      };
    }
    case actionTypes.GET_TOTAL_PARTICULAR_USER_AWARD_HISTORY: {
      return {
        ...state,
        totalParticularUserAwards: action.data,
      };
    }
    case actionTypes.GET_USER_AWARD_HISTORY_FOR_UPDATE: {
      return {
        ...state,
        userAwardHistoryForUpdate: action.data,
      };
    }
    default:
      return state;
  }
};

export default reducer;
