import React from "react";
import ReactDOM from "react-dom";
import thunk from "redux-thunk";
import { combineReducers, createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.css";
import "bootstrap-social/bootstrap-social.css";
import "react-toastify/dist/ReactToastify.css";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import App from "./App/index";
import * as serviceWorker from "./serviceWorker";
import reducer from "./store/reducer";
import config from "./config";
import "react-image-crop/dist/ReactCrop.css";
import "./index.css";
import Login from "./redux/reducers/loginReducer";
import Users from "./redux/reducers/userDataReducer";
import TaskPoints from "./redux/reducers/taskPointReducer";
import Settings from "./redux/reducers/settingsReducer";
import Product from "./redux/reducers/productReducer";
import Dashboard from "./redux/reducers/dashboardReducer";
import OrderHistory from "./redux/reducers/orderHistoryReducer";
import CommonReducer from "./redux/reducers/commonReducer";
import CompetitionReducer from "./redux/reducers/competitionReducer";
import KeyMetricsReducer from "./redux/reducers/keyMetricsReducer";
import NotificationReducer from "./redux/reducers/notificationReducer";
import { composeWithDevTools } from "redux-devtools-extension";


const reducers = combineReducers({
  reducer: reducer,
  login: Login,
  users: Users,
  taskPoints: TaskPoints,
  setting: Settings,
  product: Product,
  dashboard: Dashboard,
  orderHistory: OrderHistory,
  common: CommonReducer,
  competition: CompetitionReducer,
  keyMetrics: KeyMetricsReducer,
  notification: NotificationReducer
});

const store = createStore(reducers,
    composeWithDevTools(
        applyMiddleware(thunk),
        // other store enhancers if any
      )
);

const app = (
  <Provider store={store}>
    <NotificationContainer />
    <BrowserRouter basename={config.basename}>
      {/* basename="/datta-able" */}
      <App />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
