////////////////////////////////Constants///////////////////////////////////////////////////

export const loggingUser = "/login";
export const forgetPasswordEmail = "/otp";
export const forgetPasswordOtp = "/verify_otp_forgot";
export const resetForgetPassword = "/admin_reset_password";
export const changingPassword = "/admin_change_password";
export const addingUser = "/register";
export const gettingRecentUsers = "/get_recent_users";
export const changingStatus = "/change_user_status";
export const updatingPoints = "/create_win_lose_points";
export const gettingPoints = "/get_win_lose_points";
export const addingTaskPoint = "/create_task";
export const addingProduct = "/create_product";
export const gettingDashboardTaskPoints = "/list_task?page=1";
export const changingProductStatus = "/change_product_status";
export const gettingLevelPoint = "/get_level_points";
export const updateLevelPoints = "/create_level_points";
export const updatingUserTaskPoints = "/create_user_task_history";
export const updatingGamePoints = "/update_user_game_points";
export const gettingCardData = "/dashboard";
export const addingUserTaskPoints = "/create_user_task_history_daily_basis";
export const gettingAllUsersWithoutPagination = "/get_users";
export const addingKeyMetrics = "/create_key_metrics";
export const updatingKeyMetricPoints = "/create_user_keymetrics_history";
export const addingCompetition = "/create_campaign";
export const updatingRequestStatus = "/approve-reject-redeem-request";
export const updateCampaingsWTask = "/update_campaign_task_by_id"


/////////////////////////////////Functions///////////////////////////////////////////////////

export const gettingAllUser = (page = 1, search = null, type = "") => {
  if (search !== null) {
    return `/get_users/user?page=${page * 1}&search=${search}&filter=${type}`;
  } else {
    return `/get_users/user?page=${page}`;
  }
};

export const getUserPoints = (user_id, page) => {
  return `/get_user_campaign_by_id?user_id=${user_id}&page=${page}`;
};

export const getUserCampaingsWTask = (user_id,task_id, page) => {
  return `/get_campaign_task_by_id?user_id=${user_id}&page=${page}&task_id=${task_id}`;
};




export const gettingUser = (data) => {
  return `/user/${data}`;
};

export const updatingUser = (id) => {
  return `/updateuser/${id}`;
};

export const deletingUser = (id) => {
  return `/user/delete/${id}`;
};

export const gettingTaskHistory = (id) => {
  return `/get_user_task_history/${id}`;
};

export const gettingTaskPointsList = (page = "", search = "") => {
  if (search !== null) {
    return `/list_task?page=${page}&search=${search}`;
  } else {
    return `/list_task?page=${page}`;
  }
};

export const gettingCompetitionList = (
  page = "",
  search = "",
  filter = "ongoing",
  option = "0"
) => {
  if (search !== null) {
    return `/list_campaign?page=${page}&search=${search}&filter=${filter}&option=${option}`;
  } else {
    return `/list_campaign?page=${page}&filter=${filter}&option=${option}`;
  }
};

export const gettingTask = (id) => {
  return `/get_task/${id}`;
};

export const updatingTaskPoints = (id) => {
  return `/update_task/${id}`;
};

export const deletingTaskPoints = (id) => {
  return `/delete_task/${id}`;
};

export const gettingProductList = (page = 1, search = null) => {
  if (search !== null) {
    return `/list_product?page=${page * 1}&search=${search}`;
  } else {
    return `/list_product?page=${page}`;
  }
};

export const gettingProduct = (id) => {
  return `/get_product/${id}`;
};

export const updatingProduct = (id) => {
  return `/update_product/${id}`;
};

export const deletingProduct = (id) => {
  return `/delete_product/${id}`;
};

export const gettingOrderHistory = (page = 1, search = null) => {
  if (search !== null) {
    return `/order_history_product?page=${page * 1}&search=${search}`;
  } else {
    return `/order_history_product?page=${page}`;
  }
};

export const changingTaskPointStatus = (id) => {
  return `/update_task_status/${id}`;
};

export const gettingDailyCompetitionRecord = (
  limit = 10,
  search = "",
  page = 1,
  task = "",
  date = ""
) => {
  return `/get_dashboard_users/${limit}?page=${page}&task=${task}&search=${search}&type=${date}`;
};

export const gettingKeyMetricsList = (page = "", search = "") => {
  if (search !== null) {
    return `/list_key_metrics?page=${page}&search=${search}`;
  } else {
    return `/list_key_metrics?page=${page}`;
  }
};

export const gettingKeyMetric = (id) => {
  return `/get_key_metrics/${id}`;
};

export const updatingKeyMetrics = (id) => {
  return `/update_key_metrics/${id}`;
};

export const changingKeyMetricStatus = (id) => {
  return `/update_key_metrics_status/${id}`;
};

export const deletingKeyMetric = (id) => {
  return `/delete_key_metrics/${id}`;
};

export const gettingUserKeyMetricsHistory = (id) => {
  return `/get_user_keymetrics_history/${id}`;
};

export const gettingCompetitionDetails = (
  id,
  page = 1,
  search = "",
  type = "all"
) => {
  return `/get_campaign/${id}?page=${page}&search=${search}&datefilter=${type}`;
};

export const gettingSingleCompetition = (id) => {
  return `/get_campaign/${id}`;
};

export const updatingCompetition = (id) => {
  return `/update_campaign/${id}`;
};

export const updatingCompetitionTaskPoints = (userId, competitionId) => {
  return `/update_campaign_task_points/${userId}/${competitionId}`;
};

export const deletingCompetition = (id) => {
  return `/delete_campaign/${id}`;
};

export const updatingUserCoins = (id) => {
  return `/update_user_coins/${id}`;
};

export const gettingAllNotifications = (
  page = 1,
  search = "",
  filter = "pending"
) => {
  return `/get-redeem-requests?page=${page}&filter=${filter}&search=${search}`;
};

export const givingAwardToParticularUser = (id) => {
  return `/assign_awards/${id}`;
};

export const gettingParticularAwardHistory = (
  id,
  page = 1,
  search = "",
  type = ""
) => {
  return `/all_awards/${id}?page=${page}&filter=${type}&search=${search}`;
};

export const endCompetition = (id) => {
  return `/disable_campaign/${id}`;
};

export const gettingUserAwardHistoryForUpdate = (userId, awardId) => {
  return `/get_awards/${userId}/${awardId}`;
};

export const updatingUserAward = (id) => {
  return `/update_awards/${id}`;
};
