import * as actionTypes from "../actions/notificationAction";

const initialState = {
    allNotifications: [],
    totalNotificationCount: ''
}

const reducer = (state= initialState, action) => { 
    switch(action.type)
    {
        case actionTypes.GET_ALL_NOTIFICATIONS:{
            return {
                ...state,
                allNotifications: action.data
            }
        }
        case actionTypes.GET_NOTIFICATION_COUNT: {
            return {
                ...state,
                totalNotificationCount: action.data
            }
        }
        default: return state
    }
}

export default reducer