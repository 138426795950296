import * as url from "../../constants/urlConstants";
import axios from "../../instance";

export const GET_COMPETITIONS = "GET_COMPETITIONS";
export const GET_SINGLE_COMPETITION = "GET_SINGLE_COMPETITION";
export const GET_SINGLE_COMPETITION_TASK_DATA = "GET_SINGLE_COMPETITION_TASK_DATA";
export const GET_SINGLE_COMPETITION_USER_DATA = "GET_SINGLE_COMPETITION_USER_DATA";
export const TOTAL_COMPETITION_RECORD = "TOTAL_COMPETITION_RECORD";
export const GET_PARTICULAR_COMPETITION = "GET_PARTICULAR_COMPETITION";
export const GET_PARTICULAR_COMPETITION_USERS_LIST = "GET_PARTICULAR_COMPETITION_USERS_LIST";
export const TOTAL_COMPETITION_USERS = "TOTAL_COMPETITION_USERS";
export const GET_PARTICULAR_COMPETITION_TASKS = "GET_PARTICULAR_COMPETITION_TASKS";

export const getCompetitionList = (data) => {
    return {
        type: GET_COMPETITIONS,
        data: data
    }
}

export const getAllTasks = (data) => {
    return {
      type: TOTAL_COMPETITION_RECORD,
      data: data,
    };
  };

export const gettingCompetitionList = (page, search, filter, option) => {
    return async (dispatch) => {
        const res = await axios.get(url.gettingCompetitionList(page, search, filter, option));
        if (res.error === true) {
          return res;
        } else if (res && res.status && res.status === 500) {
          return 500;
        } else if (
          res.data &&
          res.data.response &&
          res.data.response.length === 0
        ) {
          dispatch(getCompetitionList(res.data.response));

          //   dispatch(getTaskPointList(res.data.response));
          dispatch(getAllTasks(res.data.total));
          return 404;
        } else {
          dispatch(getCompetitionList(res.data.response));
          dispatch(getAllTasks(res.data.total));
          return 200;
        }
      };
}

export const addingCompetition = (data) => {
  return async (dispatch) => {
    const res = await axios.post(url.addingCompetition, data);
    return res;
  };
}

export const getCompetitionDetails = (data) => {
  return{
    type: GET_PARTICULAR_COMPETITION,
    data: data
  }
}

export const getUsersWithRespectToCompetition = (data) => {
  return {
    type: GET_PARTICULAR_COMPETITION_USERS_LIST,
    data: data
  }
}

export const getTotalUsersCompetiton = (data) => {
  return {
    type: TOTAL_COMPETITION_USERS,
    data: data
  }
}

export const getTaskForParticularCompetition = (data) => {
  return {
    type: GET_PARTICULAR_COMPETITION_TASKS,
    data: data
  }
}

export const gettingCompetitionDetails = (id, page , search, type) => {
  return async (dispatch) => {
    const res = await axios.get(url.gettingCompetitionDetails(id,page, search, type));
    if (res.error === true) {
      return res;
    } else if (res && res.status && res.status === 500) {
      return 500;
    } else if (
      res.data &&
      res.data.data 
    ) {
      if(res.data.data.campaign)
      {
        dispatch(getCompetitionDetails(res.data.data.campaign))
      }
      if(res.data.total < 1)
      {
        dispatch(getTotalUsersCompetiton(res.data.total));
        dispatch(getTaskForParticularCompetition(res.data.data.task_data));
        return 404;
      }
      else {
        dispatch(getUsersWithRespectToCompetition(res.data.data.users_data));
        dispatch(getTaskForParticularCompetition(res.data.data.task_data));
        dispatch(getTotalUsersCompetiton(res.data.total));
        return 200;
      }
    } 
  };
}

export const getSingleCompetition = (data) => {
  return {
    type: GET_SINGLE_COMPETITION,
    data: data
  }
}

export const getSingleCompetitionTaskData = (data) => {
  return {
    type: GET_SINGLE_COMPETITION_TASK_DATA,
    data: data
  }
}

export const getSingleCompetitionUserData = (data) => {
  return {
    type: GET_SINGLE_COMPETITION_USER_DATA,
    data: data
  }
}

export const gettingSingleCompetition = (id) => {
  return async (dispatch) => {
    const res = await axios.get(url.gettingSingleCompetition(id));
    if (res.status === 200) {
      dispatch(getSingleCompetition(res.data?.data?.campaign));
      dispatch(getSingleCompetitionTaskData(res.data?.data?.task_data));
      dispatch(getSingleCompetitionUserData(res.data?.data?.users_data));
    }
    return res;
  };
}

export const updatingCompetition = (id, data) => {
  return async(dispatch) => {
    const res = await axios.post(url.updatingCompetition(id), data)
    return res;
  }
}

export const updatingCompetitionTaskPoints = (userId, competitionId, data) => {
  return async(dispatch) => {
    const res = await axios.post(url.updatingCompetitionTaskPoints(userId, competitionId), data);
    return res;
  }
}

export const deletingCompetition = (id) => {
  return async(dispatch) => {
    const res = await axios.get(url.deletingCompetition(id));
    return res
  }
}

export const endingCompetition = (id) => {
  return async(dispatch) => {
    const res = await axios.post(url.endCompetition(id));
    // console.log(res);
    return res;
  }
}